import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

let baseUrl;
if (process.env.NODE_ENV === 'development') {
  baseUrl = process.env.REACT_APP_BACKEND_LOCALAPI;
} else {
  baseUrl = process.env.REACT_APP_BACKEND_LIVEAPI;
}

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const [orderData, setOrderData] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [vtCodeData, setVtCodeData] = useState(null);

  let uid = localStorage.getItem("uid");

  useEffect(() => {
    const fetchData = async () => {

      try {
        const [ordersResponse, qrCodesResponse, vtCodesResponse] = await Promise.all([
          axios.get(`${baseUrl}/orders`),
          axios.get(`${baseUrl}/getqrcodes`),
          axios.get(`${baseUrl}/vtcodes/${uid}`)
        ]);

        setOrderData(ordersResponse.data);
        setQrCodeData(qrCodesResponse.data.data);
        setVtCodeData(vtCodesResponse.data);

        // console.log("Order data:", ordersResponse.data);
        // console.log("QR Code data:", qrCodesResponse.data);
        // console.log("VT Code data:", vtCodesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [uid]);


  return (
    <OrderContext.Provider value={{ orderData, setOrderData, qrCodeData, setQrCodeData, vtCodeData }}>
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
