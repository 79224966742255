import React, { useContext, useEffect, useState } from "react";
import "./home.css";
import heroImg from "../../images/hero.png";
import fallback from "../../images/hero.png";
import { SocialMediaContext } from "../../context/settingSociaContext";
import { SettingDataContext } from "../../context/settingDetContext";
import video from "../../images/vehiconnect.mp4";

import { Link } from "react-router-dom";

export default function Home() {


  return (
    <div className="homeLinear font-roboto mt-20 pt-10 lg:justify-start grid grid-cols-3 lg:px-16 lg:flex-row justify-center p-4 lg:text-left lg:items-center customMobflexCol lg:gap-8    customTextalign">
      <div className="lg:mx-10 bg-red  flex lg:justify-center  flex-col items-center rounded-2xl rounded-tl-xl rounded-tr-xl overflow-hidden lg:col-span-2">
        <video
          className="lg:w-full lg:h-auto w-full h-auto rounded-lg shadow-md"
          controls
          autoPlay
          loop
          // muted
          width="100%"
          height="auto"

        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

      </div>


      <div
        id="customerItem"
        className=" lg:text-left gap-2 lg:mt-0 mt-3  lg:flex lg:flex-col    lg:items-start leading-25"
      >
        <div className="">
          <span className="lg:text-left   customTextalign font-bold tracking-wide text-main ">
            <span className="text-xl  mb-2 text-main font-bold font-sans  customTextalign uppercase tracking-wider">
              {/* {getSettingData?.businessName &&
              getSettingData.businessName.slice(0, 11)} */}
              Vehi
              <span className="text-logoClr">Connect</span>
            </span>
            <br />
            Now Connect with Vehicle Owner.
          </span>
          <h1
            style={{ fontWeight: "500" }}
            className="uppercase giveSpace  text-main customTextalign mt-2.5 mx-0 mb-3 lg:text-2xl  text-4xl font-roboto tracking-wider   lg:text-left  leading-10 "
          >
            Let's make you your Vehicle Safe when it parked
          </h1>
          <p className="giveSpace  text-pgcolor  customTextalign lg:text-left sm:text-base leading-30 tracking-wide">
            Your Phone number is not shared, and anyone with a smartphone can
            contact you for any issues with your parked vehicle.
          </p>
        </div>

        <ul className="mt-3  font-roboto mb-4 flex customMobflexCol  lg:flex-row justify-center items-center text-left lg:gap-4">
          {/*  */}
          <li className="font-roboto order-1 list-square rounded-md playStore flex justify-center items-center bg-logoClr  text-white mb-4 text-sm">
            <Link
              className="flex justify-center items-center py-2.5 px-5"
              to="/shop"
            >
              <i
                style={{ color: "#ffffff", padding: "0 5px" }}
                className="fa-brands fa-2x fa-shopify"
              ></i>
              <span className="font-roboto  flex flex-col px-2 text-lg">
                <span className="font-roboto text-sm inline-flex leading-tight "></span>
                Shop Now
              </span>
            </Link>
          </li>
        </ul>
        <a
          href="https://wa.me/917073595770?text=Hello,%20I%20need%20help"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-6 right-6 bg-gradient-to-r from-[#25D366] to-[#128C7E] text-white rounded-full w-16 h-16 flex items-center justify-center shadow-lg z-50 hover:shadow-2xl transform hover:scale-110 transition-all duration-300 ease-in-out animate-pulse"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
            alt="WhatsApp"
            className="w-9 h-9"
          />
        </a>

      </div>
    </div>
  );
}
