import React, { useContext, useEffect, useState } from "react";
import "./footer.css";
import { SocialMediaContext } from "../../context/settingSociaContext";
import { SettingDataContext } from "../../context/settingDetContext";
import hzLogo from "../../images/hz_vehiclean.png";
import { Link } from "react-router-dom";
export default function () {
  const [isLoading, setIsLoading] = useState(true);
  const { socialMediaData } = useContext(SocialMediaContext);
  const [data, setData] = useState({ settingData: {}, socialMediaData: {} });
  const { settingData } = useContext(SettingDataContext);

  useEffect(() => {
    if (socialMediaData && settingData) {
      setData({ socialMediaData, settingData });
    }
    setIsLoading(false);
  }, [socialMediaData, settingData]);


  const getSettingData = data.settingData[0];
  const socialMedia = data.socialMediaData;
  return (
    <div className="dontDisplayOnmob pt-4 lg:pb-2 px16 px-2  bg-pricingcard font-normal text-pgcolor tracking-wide  ">
      <div className="MobView mt-6 flex px16  lg:flex-row justify-between items-start gap-8 px-4  text-pgcolor leading-5">
        {/* Left Section: Logo and Description */}
        <div className="flex flex-col items-start gap-4">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={hzLogo} alt="Vehismart Logo" className="w-32 lg:w-40" />
          </Link>
          <p className="text-sm lg:text-base">
            Contact vehicle owner when in <br /> VehiConnect. Call vehicle owner with privacy.
          </p>
          <ul className="flex gap-4 mt-2">
            <li>
              <a
                href={socialMedia?.facebook}
                className="w-10 h-10 flex items-center justify-center bg-[#1877F2] rounded-full hover:bg-[#1565C0]"
                aria-label="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  className="w-6 h-6"
                >
                  <path d="M22.675 0h-21.35C.594 0 0 .594 0 1.325v21.351C0 23.406.594 24 1.325 24h11.495v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.464.099 2.796.143v3.24h-1.918c-1.506 0-1.798.717-1.798 1.767v2.316h3.595l-.468 3.622h-3.127V24h6.116C23.406 24 24 23.406 24 22.676V1.325C24 .594 23.406 0 22.675 0z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href={socialMedia?.instagram}
                className="w-10 h-10 flex items-center justify-center bg-gradient-to-r from-[#E1306C] to-[#C13584] rounded-full hover:from-[#D31C6D] hover:to-[#9B0C60]"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  className="w-6 h-6"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.345 3.608 1.32.976.975 1.258 2.242 1.32 3.608.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.345 2.633-1.32 3.608-.975.976-2.242 1.258-3.608 1.32-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.345-3.608-1.32-.976-.975-1.258-2.242-1.32-3.608-.058-1.266-.07-1.646-.07-4.85s.012-3.584.07-4.85c.062-1.366.345-2.633 1.32-3.608C5.467 2.508 6.734 2.226 8.1 2.164 9.366 2.106 9.746 2.163 12 2.163zm0-2.163C8.748 0 8.328.013 7.053.072 5.776.131 4.587.37 3.576 1.38 2.566 2.39 2.328 3.579 2.269 4.856.939 6.621.929 9.465.929 12s.01 5.379.072 7.144c.059 1.276.297 2.465 1.308 3.475 1.01 1.011 2.199 1.249 3.475 1.308C8.328 23.987 8.748 24 12 24s3.672-.013 4.947-.072c1.276-.059 2.465-.297 3.475-1.308 1.01-1.01 1.249-2.199 1.308-3.475.059-1.765.072-2.185.072-4.947s-.013-3.672-.072-4.947c-.059-1.276-.297-2.465-1.308-3.475-1.01-1.01-2.199-1.249-3.475-1.308C15.672.013 15.252 0 12 0z" />
                  <circle cx="12" cy="12" r="3.516" />
                  <circle cx="17.5" cy="6.5" r="1.438" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href={socialMedia?.twitter}
                className="w-10 h-10 flex items-center justify-center bg-[#1DA1F2] rounded-full hover:bg-[#1A91DA]"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  className="w-6 h-6"
                >
                  <path d="M23.954 4.569c-.885.392-1.83.656-2.825.775 1.014-.607 1.794-1.569 2.163-2.723-.949.564-2.001.974-3.127 1.195-.896-.952-2.173-1.549-3.591-1.549-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.125-4.087-.205-7.713-2.165-10.141-5.144-.424.723-.666 1.561-.666 2.475 0 1.708.87 3.216 2.188 4.099-.807-.026-1.566-.247-2.228-.616v.062c0 2.385 1.693 4.374 3.946 4.828-.413.112-.849.171-1.296.171-.317 0-.626-.031-.928-.088.627 1.956 2.444 3.379 4.6 3.421-1.68 1.319-3.809 2.104-6.115 2.104-.398 0-.79-.023-1.175-.067 2.179 1.396 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 14.307-14.417 0-.219-.005-.437-.015-.653.982-.713 1.833-1.601 2.504-2.613z" />
                </svg>
              </a>
            </li>
          </ul>



        </div>

        {/* Middle Section: Navigation Links */}
        <div className="flex gap-12 lg:gap-8">
          <ul className="flex flex-col gap-3 text-sm lg:text-base">
            <li>
              <Link to="/shop">
                <a className="font-bold hover:underline">Shop</a>
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <a className="font-bold hover:underline">Contact</a>
              </Link>
            </li>
            <li>
              <Link to="/about">
                <a className="font-bold hover:underline">About Us</a>
              </Link>
            </li>
          </ul>
          <ul className="flex flex-col gap-3 text-sm lg:text-base">
            <li>
              <Link to="/privacy">
                <a className="font-bold hover:underline">Privacy & Policy</a>
              </Link>
            </li>
            <li>
              <Link to="/refund">
                <a className="font-bold hover:underline">Refund Policy</a>
              </Link>
            </li>
            <li>
              <Link to="/terms">
                <a className="font-bold hover:underline">Terms & Conditions</a>
              </Link>
            </li>
          </ul>
        </div>

        {/* Right Section: Contact Details */}
        <div className="flex flex-col gap-3 text-sm lg:text-base">
          <a href={`mailto:${getSettingData?.email}`} className="font-bold hover:underline">
            {getSettingData?.email}
          </a>
          <a href={`tel:+91${getSettingData?.phone}`} className="font-bold hover:underline">
            +91 {getSettingData?.phone}
          </a>
          <span className="tracking-wide">
            <a href="https://maps.app.goo.gl/qDJCPWuXXKk7mZ647" className="font-bold hover:underline">
              {getSettingData?.address}
            </a>
          </span>
        </div>
      </div>

      <hr className="mt-4" />
      <div className="flex justify-center lg:px-20 MobView mobPadd">

        <div className="mt-2 p-0 text-xs text-pgcolor">
          <p className="text-center mx-auto">
            Copyright © 2024 {getSettingData?.businessName}. All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
