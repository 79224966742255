import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../context/OrderContext"; 
import { Link, useNavigate, useParams } from "react-router-dom";
import Messages from "../Message/Messages";
import loadingGif from "../../images/loading.gif";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

export default function GetUid() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const { orderData, qrCodeData } = useContext(OrderContext);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showActivateButton, setShowActivateButton] = useState(false);
  const [userId, setUserId] = useState(null);
 
  const vehiDigit = parseInt(uid.toLowerCase().slice(2));

  useEffect(() => {
    localStorage.setItem("uid", uid.toLowerCase());
  }, [uid]);

  useEffect(() => { 
    if (!localStorage.getItem('hasReloaded')) {
       localStorage.setItem('hasReloaded', 'true');
 
      window.location.reload();

      return; 
    }
  
    if (!qrCodeData || !orderData) {
      setIsLoading(true);
      return;
    }

    const getUid = qrCodeData?.find(
      (qrcode) =>
        qrcode.uid?.toLowerCase() === uid?.toLowerCase() &&
        qrcode.qrStatus === true &&
        qrcode.qrOrderStatus === true
    );

    console.log("getUid:", getUid);

    if (getUid) {
      const getOrder = orderData?.orders.find(
        (order) => order.orderId === getUid.orderId
      );

      console.log("getOrder:", getOrder);

      if (getOrder) {
        localStorage.setItem("ownerPhoneNum", getOrder.phone);
        localStorage.setItem("ownerName", getOrder.fullName);
        localStorage.setItem("isAllowedPhone", getOrder.isAllowedPhone);
        localStorage.setItem("isAllowedMsg", getOrder.isAllowedMsg);
        localStorage.setItem('vehicleNo', getOrder.vehicleNo);
        setUserId(getOrder.userId);
        setIsLoading(false);
      } else {
        console.error("Order not found with orderId:", getUid.orderId);
        setIsLoading(false);
      }
    } else {
      console.error("No matching QR code or QR status inactive");
      setShowActivateButton(true);
      setOpen(true);
      setIsLoading(false);
    }
  }, [orderData, qrCodeData, uid]);
  useEffect(() => {
    if (userId !== null) {
      navigate(`/message`);
    }
  }, [userId, navigate]);

  const handleClose = () => {
    setOpen(false);
  };

  if (vehiDigit > 9999 || vehiDigit < 999) {
    navigate("/");
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img src={loadingGif} alt="Loading" />
      </div>
    );
  }

  return (
    <div style={{ height: "150vh" }}>
      {showActivateButton && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              padding: "20px",
              margin: "200px 10px",
            },
          }}
        >
          <DialogContent>
            <div
              style={{
                textAlign: "center",
                paddingBottom: "16px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Link to={`/activation`}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "orange",
                    color: "white",
                    padding: "10px 20px",
                    marginTop: "10px",
                  }}
                >
                  Activate Now
                </Button>
              </Link>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/* Render Messages component with userId as prop */}
      {userId && <Messages userId={userId} />}
    </div>
  );
}
